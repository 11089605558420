var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"accountPages editOrder"},[(_vm.orderInfo.orderNumber)?[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page-title hide-in-mobile"},[_c('h2',[_vm._v("Edit Order")]),_c('div',{staticClass:"page-breadcrumb"},[_c('a',{staticClass:"no-underline",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goBack()}}},[_c('SvgIcon',{attrs:{"icon":"back-arrow-line"}}),_vm._v(_vm._s(_vm.backRoute ? 'Back' : 'Home')+"\n          ")],1),_c('span',{domProps:{"innerHTML":_vm._s(`Order Number: ${_vm.orderInfo.orderNumber}`)}})]),(!_vm.isMobile)?_c('div',{staticClass:"row"},[(_vm.editOrderAlert)?_c('Alert',{attrs:{"className":"col-md-6 q-mt-sm","variant":_vm.editOrderAlert.variant,"show":_vm.alertShow},on:{"dismissed":() => {
                this.alertShow = 0
              },"dismiss-count-down":(countDown) => {
                this.alertShow = countDown
              }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.editOrderAlert.message)}})]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"orderEditItems"},[_c('div',{staticClass:"orderItem"},[(!_vm.isMobile)?_c('div',{staticClass:"orderItemTop"},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('ul',{staticClass:"orderItemInfo"},[(_vm.orderInfo.pickupStore)?_c('li',[_c('span',[_vm._v("Pickup Store")]),_vm._v("\n                      "+_vm._s(_vm.orderInfo.pickupStore.name)+"\n                    ")]):_vm._e(),(_vm.orderInfo.shippingAddress)?_c('li',[_c('span',[_vm._v("\n                        "+_vm._s(_vm.orderInfo.codeName == 'scd'
                            ? 'Deliver To'
                            : 'Ship To')+"\n                      ")]),_vm._v("\n                      "+_vm._s(_vm.orderInfo.shippingAddress.firstName +
                        ' ' +
                        _vm.orderInfo.shippingAddress.lastName)+"\n                    ")]):_vm._e(),(_vm.orderInfo.timeSlot)?_c('li',[_c('span',[_vm._v("Reserved Time Slot")]),_vm._v("\n                      "+_vm._s(_vm.orderInfo.timeSlot.day.substring(0, 3))+",\n                      "+_vm._s(_vm.orderInfo.timeSlot.slotTime)+"\n                      "),(_vm.orderInfo.updateReservedSlotCount == 0)?_c('router-link',{attrs:{"to":{
                          name: 'OrderTime',
                          params: { orderId: _vm.orderId },
                        }},nativeOn:{"click":function($event){return _vm.$store.commit('order/SET_UPDATE_TIME_SLOT', _vm.orderId)}}},[_vm._v("\n                        Change\n                      ")]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-7 actions"})])]):_vm._e(),_c('div',{staticClass:"orderItemMiddle"},[_c('div',{staticClass:"row items-center"},[(_vm.isMobile)?_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"curbBox text-center"},[_c('h3',[_c('SvgIcon',{staticClass:"q-mr-md",attrs:{"color":"primary","icon":_vm.orderInfo.codeName}}),_vm._v(_vm._s(_vm.orderInfo.displayName))],1)]),(_vm.isMobile)?_c('ul',{staticClass:"curbOrderInfo autoHeight"},[_c('li',[_c('span',[_c('label',[_vm._v("ORDER NUMBER")]),_c('strong',{domProps:{"innerHTML":_vm._s(_vm.orderInfo.orderNumber)}})])]),(_vm.orderInfo.pickupStore)?_c('li',[_c('span',[_c('label',[_vm._v("Pickup Store")]),_c('strong',{domProps:{"innerHTML":_vm._s(_vm.orderInfo.pickupStore.name)}})])]):_vm._e(),(_vm.orderInfo.shippingAddress)?_c('li',[_c('span',[_c('label',[_vm._v("\n                          "+_vm._s(_vm.orderInfo.codeName == 'scd'
                              ? 'Deliver To'
                              : 'Ship To')+"\n                        ")]),_c('strong',[_vm._v("\n                          "+_vm._s(_vm.orderInfo.shippingAddress.firstName +
                            ' ' +
                            _vm.orderInfo.shippingAddress.lastName)+"\n                        ")])])]):_vm._e(),(_vm.orderInfo.timeSlot)?_c('li',[_c('span',[_c('label',[_vm._v("Reserved Time Slot")]),_c('strong',[_vm._v("\n                          "+_vm._s(_vm.orderInfo.timeSlot.day.substring(0, 3))+",\n                          "+_vm._s(_vm.orderInfo.timeSlot.slotTime)+"\n                        ")]),(_vm.orderInfo.updateReservedSlotCount == 0)?_c('router-link',{attrs:{"to":{
                            name: 'OrderTime',
                            params: { orderId: _vm.orderId },
                          }},nativeOn:{"click":function($event){return _vm.$store.commit(
                              'order/SET_UPDATE_TIME_SLOT',
                              _vm.orderId
                            )}}},[_vm._v("\n                          Change\n                        ")]):_vm._e()],1)]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.orderInfo.updateReservedSlotCount == 0)?_c('div',{staticClass:"col-12 col-md-12 slotTime-change-txt"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8"},[_vm._v("\n                      * Time slots can be updated once per order. If you have\n                      an issue with your order, please contact the store.\n                    ")])])]):_vm._e(),(
                    _vm.orderInfo.isEditTillDatetime || _vm.showSubscriptionBanner
                  )?_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('q-banner',{staticClass:"alert-warning alert-sm q-mb-md",attrs:{"inline-actions":"","dense":"","rounded":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderInfo.isEditTillDatetime),expression:"orderInfo.isEditTillDatetime"}]},[_vm._v("\n                          Modify your order until it is moved under\n                          processing. Once your order status is changed to\n                          processing, it can no longer be modified.\n                        ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSubscriptionBanner),expression:"showSubscriptionBanner"}]},[_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderInfo.isEditTillDatetime),expression:"orderInfo.isEditTillDatetime"}]}),_vm._v("\n                          The subscribed product is in your order as per the\n                          scheduled date. Any changes, such as increasing or\n                          decreasing the product quantity, will apply to this\n                          order only. To manage your subscription details for\n                          future orders, please visit the '"),_c('router-link',{staticClass:"text-underline text-primary",attrs:{"to":{
                              name: 'ManageSubscription',
                            },"target":"_blank"}},[_vm._v("Manage My Subscription")]),_vm._v("' menu.\n                        ")],1)])],1)])]):_vm._e(),(
                    _vm.isMobile &&
                    _vm.orderInfo.appliedLnsCoupons &&
                    _vm.orderInfo.appliedLnsCoupons.length
                  )?_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('q-banner',{staticClass:"alert-warning alert-sm q-mb-md",attrs:{"inline-actions":"","dense":"","rounded":""}},[_c('strong',[_vm._v("Note: ")]),_vm._v("Coupons may be removed from the\n                        order if the applicable items are removed either by\n                        editing the order or being out of stock.\n                      ")])],1)])]):_vm._e(),_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"row editOrderTableTitle"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h4',[_vm._v("Items ("+_vm._s(_vm.orderInfo.totalItems)+")")])]),_c('div',{staticClass:"col-12 col-md-6 text-right"},[(_vm.orderInfo.isEditTillDatetime)?[_vm._v("\n                        Add more items to this order before it moved under the\n                        processing\n                        ")]:_vm._e(),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"unelevated":"","no-caps":"","rounded":!_vm.isMobile,"color":"secondary","size":"sm","label":"Add item"},on:{"click":function($event){return _vm.changeDialogState({
                            dialog: 'AddOrderItems',
                            val: true,
                          })}}})],2),_c('div',{staticClass:"col-12 col-md-12"},[_c('q-separator',{staticClass:"q-mt-md q-mb-md"})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCancelOrder),expression:"!isCancelOrder"}],staticClass:"col-12 col-md-12"},[(_vm.orderInfo.codeName.toLowerCase() !== 'sd')?_c('AllowSubstitutions',{attrs:{"isFor":"order","orderId":_vm.orderId}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"cart-section"},_vm._l((_vm.orderItems),function(item){return _c('div',{key:item.productId,staticClass:"cart-order"},[_c('div',{staticClass:"cart-product-left"},[(_vm.businessConfigurations.enableSubstitution)?_c('div',{class:[
                            {
                              blacklistedproduct: item.isBlacklisted,
                            },
                            {
                              disabled: item.isDeleted,
                            },
                          ]},[(_vm.orderInfo.codeName.toLowerCase() !== 'sd')?_c('q-checkbox',{attrs:{"color":"secondaryOnBody","disable":item.isDeleted || item.isBlacklisted,"value":item.allowSubstitute},on:{"input":function($event){return _vm.toggleSubstitute(item)}}}):_vm._e()],1):_vm._e(),(_vm.isMobile)?[(!item.isDeleted)?_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","padding":"0"},on:{"click":function($event){return _vm.deleteLineItem(item)}}},[_c('SvgIcon',{attrs:{"color":"colorful","size":"1rem","icon":"trash"}})],1)],1):_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","flat":"","color":"red","padding":"0"},on:{"click":function($event){return _vm.restoreItem(item)}}},[_c('q-icon',{attrs:{"size":"1.2rem","color":"#010101","name":"restore_from_trash"}})],1)],1)]:_vm._e()],2),_c('div',{staticClass:"cart-product",class:[
                          {
                            itemDeleted: item.isDeleted,
                            'substitution-product':
                              (item.linkedProducts &&
                                item.linkedProducts.length > 0) ||
                              item.allowSubstitute,
                          },
                        ]},[_c('div',{staticClass:"cart-produt-data"},[_c('div',{staticClass:"produt-data flex items-start"},[_c('div',{staticClass:"product-image",class:item.isDeleted ? 'disabled' : ''},[_c('box-image',{attrs:{"link":{
                                  name: 'Detail',
                                  params: { seoName: item.seoName },
                                },"imageSrc":item.image,"width":_vm.isMobile ? '80px' : '86px'}})],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h3',{staticClass:"ellipsis",class:item.isDeleted ? 'disabled' : ''},[(item.isBlacklisted)?[_vm._v("\n                                    "+_vm._s(item.productName))]:_c('router-link',{attrs:{"to":{
                                      name: 'Detail',
                                      params: { seoName: item.seoName },
                                    },"target":"_blank"}},[_vm._v("\n                                    "+_vm._s(item.productName)+"\n                                  ")])],2),_c('div',{staticClass:"product--labeles q-mt-sm"},[(item.isAdded)?_c('span',{staticClass:"label-success",domProps:{"innerHTML":_vm._s(`Just Added`)}}):(item.isUpdated)?_c('span',{staticClass:"label-primary",domProps:{"innerHTML":_vm._s(`Updated`)}}):_vm._e(),(item.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No\n                                    Longer on Site")],1):_vm._e()]),(!item.isAdded && item.frequency > 0)?_c('q-banner',{staticClass:"alert-warning alert-sm text-subtitle2 inline-block",attrs:{"inline-actions":"","dense":"","rounded":""}},[_vm._v("\n                                  Recurring in every\n                                  "),_c('strong',{domProps:{"textContent":_vm._s(_vm.getSubFrequency(item.frequency))}})]):_vm._e(),(
                                    !item.isDeleted && !item.isBlacklisted
                                  )?_c('OrderLevelinstruction',{attrs:{"productId":item.productId,"product":item,"isfromEditOrderLevel":true,"orderId":_vm.orderId}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7 col-md-6"},[(
                                        _vm.orderInfo.codeName == 'scd' &&
                                        item.isAdded &&
                                        item?.isSubscribeForAutoShip
                                      )?_c('ProductSubscriptionOptions',{key:item.productId,ref:"productSubscriptionOptions",refInFor:true,staticClass:"q-mt-md",attrs:{"pageName":"OrderEdit","product":item,"productId":item.productId,"orderId":_vm.orderId}}):_vm._e()],1)]):_vm._e()],1),_c('div',{staticClass:"price",class:item.isDeleted ? 'disabled' : ''},[(item.totalPrice != item.finalPrice)?_c('span',{staticClass:"original-price"},[_vm._v("\n                                  "+_vm._s(_vm._f("currency")(item.totalPrice))+"\n                                ")]):_vm._e(),_c('span',{class:item.totalPrice != item.finalPrice
                                      ? 'discount-price'
                                      : ''},[_vm._v("\n                                  "+_vm._s(_vm._f("currency")(item.finalPrice))+"\n                                ")])]),(!_vm.isMobile)?_c('div',{staticClass:"flex product-edit--options"},[_c('div',{class:[
                                    'product-quantity',
                                    {
                                      blacklistedproduct: item.isBlacklisted,
                                    },
                                    item.isDeleted ? 'disabled' : '',
                                  ]},[_c('OrderCartButton',{attrs:{"showSelectButton":false,"productId":item.productId,"product":item,"updateUsingAPI":true,"orderId":_vm.orderId,"disabled":item.isDeleted && item.isBlacklisted}}),_c('span',{staticClass:"other-options q-ml-sm"},[_vm._v("\n                                    x "+_vm._s(_vm._f("currency")(item.price))+"\n                                    "),(false)?_c('span',{staticClass:"text-strike",domProps:{"innerHTML":_vm._s(
                                        _vm.$options.filters.currency(
                                          item.compareAtPrice
                                        )
                                      )}}):_vm._e()])],1),_c('div',{staticClass:"product-delete"},[(!item.isDeleted)?_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","padding":"0"},on:{"click":function($event){return _vm.deleteLineItem(item)}}},[(_vm.isMobile)?_c('SvgIcon',{attrs:{"color":"colorful","size":"1rem","icon":"trash"}}):[_vm._v("Delete")]],2)],1):_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","flat":"","color":"red","padding":"0"},on:{"click":function($event){return _vm.restoreItem(item)}}},[(_vm.isMobile)?_c('q-icon',{attrs:{"size":"1.5rem","color":"#010101","name":"restore_from_trash"}}):[_vm._v("Restore")]],2)],1)])]):_vm._e()]),(_vm.isMobile)?_c('div',{staticClass:"row full-width"},[_c('div',{staticClass:"col-12 col-sm-7 col-md-6"},[(
                                    item.isAdded &&
                                    item.codeName != 'sp' &&
                                    item?.isSubscribeForAutoShip
                                  )?_c('ProductSubscriptionOptions',{key:item.productId,ref:"productSubscriptionOptions",refInFor:true,staticClass:"autoship-edit-order q-mt-md",attrs:{"pageName":"OrderEdit","product":item,"productId":item.productId,"orderId":_vm.orderId}}):_vm._e()],1)]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"flex product-edit--options"},[_c('div',{class:[
                                  'product-quantity',
                                  {
                                    blacklistedproduct: item.isBlacklisted,
                                  },
                                  item.isDeleted ? 'disabled' : '',
                                ]},[_c('OrderCartButton',{attrs:{"showSelectButton":false,"productId":item.productId,"product":item,"updateUsingAPI":true,"orderId":_vm.orderId,"disabled":item.isDeleted && item.isBlacklisted}}),_c('span',{staticClass:"other-options q-ml-sm"},[_vm._v("\n                                  x "+_vm._s(_vm._f("currency")(item.price))+"\n                                  "),(false)?_c('span',{staticClass:"text-strike",domProps:{"innerHTML":_vm._s(
                                      _vm.$options.filters.currency(
                                        item.compareAtPrice
                                      )
                                    )}}):_vm._e()])],1),_c('div',{staticClass:"product-delete"},[(!item.isDeleted)?_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","padding":"0"},on:{"click":function($event){return _vm.deleteLineItem(item)}}},[(_vm.isMobile)?_c('SvgIcon',{attrs:{"color":"colorful","size":"1rem","icon":"trash"}}):[_vm._v("Delete")]],2)],1):_c('div',{staticClass:"cartItemRemove"},[_c('q-btn',{staticClass:"no-hover",attrs:{"no-caps":"","unelevated":"","flat":"","color":"red","padding":"0"},on:{"click":function($event){return _vm.restoreItem(item)}}},[(_vm.isMobile)?_c('q-icon',{attrs:{"size":"1.5rem","color":"#010101","name":"restore_from_trash"}}):[_vm._v("Restore")]],2)],1)])]):_vm._e()])]),(
                            item.linkedProducts &&
                            item.linkedProducts.length > 0
                          )?_c('div',{staticClass:"substitution-product--block"},_vm._l((item.linkedProducts),function(linkedProduct,index){return _c('div',{key:`${linkedProduct.orderLineItemID}${index}`,class:[
                              'substitution-product produt-data flex items-center',
                              {
                                blacklistedproduct:
                                  linkedProduct.isBlacklisted,
                              },
                            ]},[_c('div',{staticClass:"product-image"},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":linkedProduct.isSellable
                                      ? {
                                          name: 'Detail',
                                          params: {
                                            seoName: linkedProduct.seoName,
                                          },
                                        }
                                      : '',"imageSrc":linkedProduct.image,"width":"40px"}})],1),_c('q-avatar',{attrs:{"flat":""}},[_c('SvgIcon',{attrs:{"icon":"linked"}})],1)],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h4',[(linkedProduct.isSellable)?_c('router-link',{attrs:{"to":{
                                      name: 'Detail',
                                      params: {
                                        seoName: linkedProduct.seoName,
                                      },
                                    }},domProps:{"innerHTML":_vm._s(linkedProduct.productName)}}):[_vm._v("\n                                    "+_vm._s(linkedProduct.productName)+"\n                                  ")],_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                                    "+_vm._s(linkedProduct.productName)+"\n                                  ")])],2)]),_c('div',{staticClass:"price"},[(
                                    linkedProduct.finalPrice &&
                                    linkedProduct.totalPrice !=
                                      linkedProduct.finalPrice
                                  )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(
                                    _vm.$options.filters.currency(
                                      linkedProduct.totalPrice
                                    )
                                  )}}):_vm._e(),_c('span',{class:linkedProduct.finalPrice &&
                                    linkedProduct.totalPrice !=
                                      linkedProduct.finalPrice
                                      ? 'discount-price'
                                      : '',domProps:{"innerHTML":_vm._s(
                                    _vm.$options.filters.currency(
                                      linkedProduct.finalPrice ||
                                        linkedProduct.totalPrice
                                    )
                                  )}})]),_c('div',{staticClass:"flex product-edit--options"},[_c('div',{staticClass:"product-quantity"},[_c('span',{domProps:{"innerHTML":_vm._s(
                                      `${
                                        linkedProduct.qty
                                      } X ${_vm.$options.filters.currency(
                                        linkedProduct.price
                                      )}`
                                    )}})])])])])}),0):_vm._e(),(
                            item.allowSubstitute &&
                            !item.isDeleted &&
                            item.substituteProductListData &&
                            item.substituteProductListData.length > 0 &&
                            item.substituteProductListData[0]
                              .substituteProductDetails.length > 0
                          )?_c('div',{staticClass:"substitution-product--sibling"},[_c('div',{staticClass:"division"},[_c('span',[_vm._v("Choose your preferred Substitution")])]),_c('div',{staticClass:"row q-col-gutter-sm substitution-product--items"},_vm._l((item
                                .substituteProductListData[0]
                                .substituteProductDetails),function(substituteProduct,index){return _c('div',{key:`${item.substituteProductListData[0].orderLineItemID}${index}`,staticClass:"col-12",class:substituteProduct.productName ==
                                'No Preference'
                                  ? 'col-md-12 order-first'
                                  : 'col-md-6'},[_c('div',{staticClass:"produt-data flex items-start"},[[_c('q-radio',{attrs:{"dense":"","color":"secondaryOnBody","val":_vm.selectedChildSubstituteProduct(
                                        item.orderLineItemID
                                      ) ||
                                      item.selectedSubstituteProductId ||
                                      (substituteProduct.substituteProductID ==
                                      '00000000-0000-0000-0000-000000000000'
                                        ? substituteProduct.substituteProductID
                                        : ''),"value":substituteProduct.substituteProductID},on:{"input":() => {
                                        _vm.toggleSubstituteChildProduct({
                                          orderLineItemID:
                                            item.orderLineItemID,
                                          allowSubstitute:
                                            item.allowSubstitute,
                                          substituteProductID:
                                            substituteProduct.substituteProductID,
                                        })
                                      }}})],(
                                    substituteProduct.productName !=
                                    'No Preference'
                                  )?_c('div',{staticClass:"product-image"},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":{
                                        name: 'Detail',
                                        params: {
                                          seoName: substituteProduct.seoName,
                                        },
                                      },"imageSrc":_vm._f("defaultProductImage")(substituteProduct.image),"width":"45px"}})],1)],1):_vm._e(),(
                                    substituteProduct.productName !=
                                    'No Preference'
                                  )?_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h5',[_c('router-link',{attrs:{"to":{
                                          name: 'Detail',
                                          params: {
                                            seoName:
                                              substituteProduct.seoName,
                                          },
                                        }},domProps:{"innerHTML":_vm._s(substituteProduct.productName)}})],1)]),_c('div',{staticClass:"price",domProps:{"innerHTML":_vm._s(
                                      _vm.$options.filters.currency(
                                        substituteProduct.price
                                      )
                                    )}})]):_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h5',[_vm._v("\n                                      "+_vm._s(substituteProduct.productName)+"\n                                    ")])])])],2)])}),0)]):_vm._e()])])}),0)]),_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"orderSummaryInfos"},[_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-4 col-lg-4 o-1"},[_c('ul',{staticClass:"orderSummaryETotal"},[_c('li',[_vm._v("\n                            Sub Total\n                            "),_c('span',[_vm._v("\n                              "+_vm._s(_vm._f("currency")(_vm.orderInfo.subTotal))+"\n                              "),(_vm.orderInfo.additionalSubTotal)?[_vm._v("\n                                "+_vm._s(_vm.orderInfo.additionalSubTotal > 0
                                    ? '+ '
                                    : '- ')+_vm._s(_vm._f("currency")(Math.abs(_vm.orderInfo.additionalSubTotal)))+"\n                              ")]:_vm._e()],2)]),(_vm.orderInfo.tipAmount)?[_c('li',[_vm._v("\n                              Tip\n                              "),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.orderInfo.tipAmount,false, true)))])])]:_vm._e(),(
                              _vm.orderInfo.additionalCharges &&
                              _vm.orderInfo.additionalCharges.length
                            )?_vm._l((_vm.orderInfo.additionalCharges),function(additionalCharge){return _c('li',{key:additionalCharge.chargeName},[_vm._v("\n                              "+_vm._s(additionalCharge.chargeName)+"\n                              "),_c('span',[_vm._v(_vm._s(_vm._f("currency")(additionalCharge.chargeAmount,false, true)))])])}):_vm._e(),(
                              _vm.orderInfo &&
                              _vm.orderInfo.codeName &&
                              _vm.orderInfo.codeName.toLowerCase() === 'scd' &&
                              _vm.orderInfo.shippingRate
                            )?[_c('li',[_vm._v("\n                              Delivery Fee\n                              "),_c('span',[[_vm._v("\n                                  "+_vm._s(_vm._f("currency")(_vm.orderInfo.shippingRate,false, true))+"\n                                ")]],2)])]:_vm._e(),(_vm.orderInfo.discount)?[_c('li',[_vm._v("\n                              Discount Applied\n                              "),_c('span',{domProps:{"innerHTML":_vm._s(
                                  _vm.$options.filters.currency(
                                    _vm.orderInfo.discount,
                                    true
                                  )
                                )}})])]:_vm._e(),(_vm.orderInfo.buydown)?_c('li',[_vm._v("\n                            Other Discount\n                            "),_c('span',[_vm._v("\n                              -\n                              "+_vm._s(_vm._f("currency")(_vm.orderInfo.buydown))+"\n                            ")])]):_vm._e(),(_vm.orderInfo.appliedLnsDollars)?_c('li',[_vm._v("\n                            Loyalty Dollars Applied\n                            "),_c('q-btn',{staticClass:"redeem-remove",attrs:{"flat":"","unelevated":"","dense":"","loading":_vm.removingPoints},on:{"click":_vm.removePoints}},[_c('SvgIcon',{staticClass:"text-negative",attrs:{"icon":"close","size":"12px"}})],1),_c('span',[_vm._v("\n                              -"+_vm._s(_vm._f("currency")(_vm.orderInfo.appliedLnsDollars,true))+"\n                            ")])],1):_vm._e(),(
                              _vm.orderInfo.otherTax ||
                              _vm.orderInfo.additionalTotalTax
                            )?[_c('li',[_vm._v("\n                              Tax\n                              "),_c('span',[_vm._v("\n                                "+_vm._s(_vm._f("currency")(_vm.orderInfo.otherTax,false, true))+"\n                                "),(_vm.orderInfo.additionalTotalTax)?[_vm._v("\n                                  "+_vm._s(_vm.orderInfo.additionalTotalTax > 0
                                      ? '+ '
                                      : '- ')+_vm._s(_vm._f("currency")(Math.abs(_vm.orderInfo.additionalTotalTax)))+"\n                                ")]:_vm._e()],2)])]:_vm._e(),(_vm.orderInfo.occupationalTax)?[_c('li',[_vm._v("\n                              Occupational Tax\n                              "),_c('span',[_vm._v("\n                                "+_vm._s(_vm._f("currency")(_vm.orderInfo.occupationalTax,false, true))+"\n                              ")])])]:_vm._e(),(
                              _vm.orderInfo.appliedLnsCoupons &&
                              _vm.orderInfo.appliedLnsCoupons.length
                            )?[_c('strong',[_c('p',{staticClass:"q-mt-sm applied-coupons"},[_vm._v("Applied Loyalty Coupon(s)")])]),_c('q-separator'),_vm._l((_vm.orderInfo.appliedLnsCoupons),function(appliedLnsCoupon){return _c('li',{key:appliedLnsCoupon.id,staticStyle:{"padding-right":"0px !important"}},[_c('div',[_c('span',[_vm._v("\n                                  "+_vm._s(_vm.appliedCouponName(appliedLnsCoupon.name))+"\n                                  "),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                                    "+_vm._s(appliedLnsCoupon.name)+"\n                                  ")])],1)]),_c('span',{staticStyle:{"width":"unset !important"}},[(_vm.isLnsSubscribed)?_c('q-btn',{staticClass:"coupon-remove-btn no-hover",attrs:{"unelevated":"","flat":"","dense":"","round":"","size":"sm","color":"red"},on:{"click":function($event){return _vm.removeCoupons(appliedLnsCoupon.couponID)}}},[_c('SvgIcon',{attrs:{"size":"1rem","icon":"close-circle"}})],1):_vm._e(),_vm._v("\n                                - "+_vm._s(_vm._f("currency")(appliedLnsCoupon.amount))+"\n                              ")],1)])})]:_vm._e(),(
                              _vm.orderInfo.appliedDiscountCoupons &&
                              _vm.orderInfo.appliedDiscountCoupons.length
                            )?[_c('strong',[_c('p',{staticClass:"q-mt-sm applied-coupons"},[_vm._v("Applied Discount Coupons")])]),_c('q-separator'),_vm._l((_vm.orderInfo.appliedDiscountCoupons),function(appliedDiscountCoupon){return _c('li',{key:appliedDiscountCoupon.id,staticStyle:{"padding-right":"0px !important"}},[_c('div',[_c('span',[_vm._v("\n                                  "+_vm._s(appliedDiscountCoupon.couponCode)+"\n                                  "),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                                    "+_vm._s(appliedDiscountCoupon.couponCode)+"\n                                  ")])],1)]),_c('span',{staticStyle:{"width":"unset !important"}},[_c('q-btn',{staticClass:"coupon-remove-btn no-hover",attrs:{"unelevated":"","flat":"","dense":"","round":"","size":"sm","color":"red"},on:{"click":function($event){return _vm.removeDiscountCoupons(
                                      appliedDiscountCoupon.id
                                    )}}},[_c('SvgIcon',{attrs:{"size":"1rem","icon":"close-circle"}})],1),_vm._v("\n                                "+_vm._s(_vm._f("currency")(appliedDiscountCoupon.amount))+"\n                              ")],1)])})]:_vm._e(),[_c('div',{staticClass:"q-mt-sm"}),_c('li',{staticClass:"eredeem q-mt-md"},[_c('div',{staticClass:"orderSummaryPromocode redeem-code"},[_c('q-form',{on:{"submit":_vm.applyDiscountCoupons}},[_c('h4',{staticClass:"lda-point"},[_c('span',{staticClass:"lda-point-title"},[_vm._v("Redeem Coupon")])]),_c('div',{staticClass:"promocodeForm"},[_c('q-input',{staticClass:"promo-input",attrs:{"outlined":"","placeholder":"Enter Coupon Code"},model:{value:(_vm.couponCode),callback:function ($$v) {_vm.couponCode=$$v},expression:"couponCode"}}),_c('q-btn',{staticClass:"promo-btn text-capitalize",attrs:{"type":"submit","label":"Apply","disabled":_vm.submitCoupon,"loading":_vm.submitCoupon}})],1)])],1)])],(_vm.isLnsSubscribed)?_c('div',{staticClass:"q-mt-xs"}):_vm._e(),_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[_c('Alert',{directives:[{name:"show",rawName:"v-show",value:(
                                !_vm.orderInfo.finalPayAmount &&
                                _vm.orderInfo.appliedLnsDollars
                              ),expression:"\n                                !orderInfo.finalPayAmount &&\n                                orderInfo.appliedLnsDollars\n                              "}],attrs:{"show":"","variant":"warning","className":"q-mt-sm"}},[_vm._v("\n                              We will not charge your payment method for any\n                              orders totaling out to $0.00. However, all\n                              orders must have a temporary authorization hold\n                              of at least $0.01 to verify the payment method\n                              in case additional items are added\n                            ")])],1),_c('q-separator'),_c('li',{staticClass:"total"},[_vm._v("\n                            Order Total\n                            "),_c('span',[_vm._v("\n                              "+_vm._s(_vm._f("currency")(_vm.orderInfo.finalPayAmount))+"\n                            ")]),(_vm.isLnsSubscribed)?_c('div',{staticClass:"orderSummaryCouponcode"},[_c('a',{staticClass:"promo-link float-right",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.couponsDialog}},[_vm._v("\n                                Browse Loyalty Coupons\n                              ")])]):_vm._e()]),(_vm.isLnsSubscribed)?[_c('li',{staticClass:"eredeem q-mt-md"},[_c('div',{staticClass:"orderSummaryPromocode redeem-code"},[_c('q-form',{on:{"submit":_vm.applyPoints}},[_c('h4',{staticClass:"lda-point"},[_c('strong',{staticClass:"lda-point-title"},[_vm._v("\n                                      Redeem Dollars\n                                      "),_c('span',{staticClass:"float-right"},[_c('SvgIcon',{staticClass:"q-mr-xs",attrs:{"icon":"token","size":"18px"}}),_c('span',{staticClass:"verticl-middle"},[_c('strong',[_vm._v("\n                                            "+_vm._s(_vm._f("currency")(_vm.totalRedeemedDollars))+"\n                                          ")])]),(_vm.orderInfo.additionalLnsDollar)?_c('span',[(
                                              _vm.orderInfo.additionalLnsDollar &&
                                              !_vm.orderInfo.appliedLnsDollars
                                            )?_c('strong',[_vm._v("\n                                            +\n                                            "+_vm._s(_vm._f("currency")(_vm.orderInfo.additionalLnsDollar,true))+"\n                                          ")]):_c('strong',[_vm._v("\n                                            -\n                                            "+_vm._s(_vm._f("currency")(_vm.orderInfo.appliedLnsDollars,true))+"\n                                          ")])]):_vm._e()],1)])]),(_vm.totalRedeemedDollars > 0)?_c('div',{staticClass:"promocodeForm"},[_c('q-currency-input',{staticClass:"promo-input",attrs:{"options":{
                                        distractionFree: false,
                                        currency: 'USD',
                                        locale: 'en',
                                      },"placeholder":"Redeem Loyalty Dollars"},model:{value:(_vm.redeemDolors),callback:function ($$v) {_vm.redeemDolors=$$v},expression:"redeemDolors"}}),_c('q-btn',{staticClass:"promo-btn text-capitalize",attrs:{"type":"submit","label":"Redeem","size":"md","disabled":_vm.submitredeem,"loading":_vm.submitredeem}})],1):_vm._e()])],1)])]:_vm._e()],2)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-5 col-lg-5 o-3"},[_c('div',{staticClass:"row q-col-gutter-x-lg"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-10"},[_c('h5',{class:_vm.isMobile ? 'text-primary' : ''},[_vm._v("\n                              Write Order Instructions\n                            ")]),_c('div',{staticClass:"pb-0"},[_c('q-input',{attrs:{"outlined":"","value":_vm.orderInfo.deliveryInstruction,"type":"textarea","color":"grey","label":"Enter Instruction","maxlength":"255","autogrow":""},on:{"input":_vm.updateDeliveryInstruction}}),_c('span',{staticClass:"float-right text-caption"},[_vm._v(_vm._s(_vm.orderInfo.deliveryInstruction?.length || 0)+"/255")])],1)])])]),(
                          _vm.orderInfo.isPayAtStore ||
                          _vm.orderInfo.orderPaymentDetail
                        )?_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-3 col-lg-3 o-2"},[_c('h5',[_vm._v("Payment Method")]),(_vm.orderInfo.isPayAtStore)?_c('p',[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"size":"44px","icon":"pay-at-store"}}),_vm._v("\n                          Pay at store\n                        ")],1):(_vm.orderInfo.orderPaymentDetail)?_c('p',[_vm._v("\n                          Credit Card"),_c('br'),_c('span',{class:`paymentIcon paymentIcon-left`},[_c('img',{attrs:{"src":`images/payment/${
                                _vm.getCardType(
                                  _vm.orderInfo.orderPaymentDetail.cardType
                                ).logo
                              }`,"title":_vm.getCardType(
                                  _vm.orderInfo.orderPaymentDetail.cardType
                                ).title}})]),_vm._v("\n                          xxxx-xxxx-xxxx-"+_vm._s(_vm.orderInfo.orderPaymentDetail.cardLast4Digits)+"\n                          "),_c('span',{staticClass:"text-caption acknowledge-acceptance-msg",domProps:{"innerHTML":_vm._s(_vm.acknowledgeAcceptanceMessage)}})]):_vm._e()]):_vm._e()])])]),(_vm.orderInfo.temporaryHoldAmount)?_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"orderHoldAmount"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        `${
                          _vm.orderInfo.temporaryHoldAmount > 0
                            ? 'Additional'
                            : ''
                        } Temporary hold: ${
                          _vm.orderInfo.temporaryHoldAmount > 0 ? '+' : '-'
                        }${_vm.$options.filters.currency(
                          Math.abs(_vm.orderInfo.temporaryHoldAmount)
                        )}`
                      )}}),_c('p',[_vm._v("\n                      The temporary hold is the amount we authorize to ensure\n                      there are funds to complete your purchase. This isn't a\n                      charge. "),_c('br'),_vm._v("Your order total may vary to account for\n                      weighted items, like meat produce, and any bag fees in\n                      your state. "),_c('br'),_vm._v("You will only be charged for the final\n                      order total once your order is picked up or delivered.\n                      Your bank should remove the authorization hold on your\n                      card within 3-5 business days.\n                    ")])])]):_vm._e()])])])]),_c('div',{staticClass:"orderEditActions"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6"}),_c('div',{staticClass:"col-12 col-sm-12 col-md-6 q-pt-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm"},[_c('div',{staticClass:"form-action"},[_c('q-btn',{staticClass:"full-width q-btn-lg",attrs:{"unelevated":"","no-caps":"","rounded":!_vm.isMobile,"color":"tertiary","label":_vm.backRoute ? 'Go Back' : 'Go to Home',"size":"1rem"},on:{"click":function($event){return _vm.cancelOrderEdit()}}})],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm"},[_c('div',{staticClass:"form-action"},[_c('q-btn',{staticClass:"full-width q-btn-lg",attrs:{"unelevated":"","no-caps":"","rounded":!_vm.isMobile,"color":"secondaryOnBody","label":_vm.orderInfo.ageRequired > 0
                          ? 'Verify Age and Update'
                          : 'Update',"size":"1rem"},on:{"click":_vm.updateOrder}})],1)])])])])])])]),_c('AddOrderItemDialog',{attrs:{"displayName":_vm.orderInfo.displayName,"codeName":_vm.orderInfo.codeName,"orderId":_vm.orderId}}),_c('TriggerListDialog')]:_vm._e(),(_vm.isMobile)?_c('ItemInstructionMobile'):_vm._e(),_c('AlreadySubscribedProducts')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }